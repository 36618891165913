
.App {
  text-align: center;
  color: #ffffff;
  padding-bottom: env(safe-area-inset-bottom);
  background-color: #000000;
}

@media (min-width: 569px) {
  .App {
    padding-top: unset;
    .bodyPadding { 
      padding: 0 7.5%;
    }
  }
}