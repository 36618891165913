@import '../../../assets/style/default-imports.scss';

.landing {
  display: flex;
  background-color: #000000;
  padding: 10% 2.5%;
  flex-direction: column;
  justify-content: space-between;
  height: 150vh;
  gap: 2.5%;
  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .jobTitle {
      font-size: 24px;
      font-weight: 100;
      margin: 5px 0 0 0;
    }
    .name {
      font-size: 60px;
      font-weight: lighter;
      margin: 0;
    }
  }
  .center {
    .logo {
      height: 250px;
      width: 250px;
    }
  }
  .right {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: flex-end;
    gap: 5px;
    .icon {
      height: 35px;
      width: 35px;
    }
  }
}

@media (min-width: $mobile) {
  .landing {
    height: 100vh;
    .left {
      .name {
        font-size: 80px;
      }
      .jobTitle {
        font-size: 36px;
      }
    }
    .center {
      .logo {
        height: 350px;
        width: 350px;
      }
    }
    .right {
      .icon {
        height: 60px;
        width: 60px;
      }
    }
  }
}

@media (min-width: $tablet) {
  .landing {
    height: 80vh;
    .left {
      flex: 1;
      .name {
        font-size: 100px;
      }
      .jobTitle {
        font-size: 48px;
      }
    }
    .center {
      flex: 1;

      .logo {
        height: 500px;
        width: 500px;
      }
    }
    .right {
      flex: 1;

      .icon {
        height: 75px;
        width: 75px;
      }
    }
  }
}

@media (min-width: $lg-desktop) {
  .landing {
    height: 75vh;
    flex-direction: row;
    .left {
      align-items: flex-start;
      justify-content: flex-start;
      flex: 1;
      .jobTitle {
        font-size: 36px;
      }
      .name {
        font-size: 100px;
      }
    }
    .center {
      flex: 1;
      .logo {
        height: 500px;
        width: 500px;
      }
    }
    .right {
      flex: 1;
      justify-content: flex-end;
      align-items: flex-end;
      .icon {
        height: 75px;
        width: 75px;
      }
    }
  }
}
