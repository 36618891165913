@import '../../assets/style/default-imports.scss';

.about {
  font-size: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2e2d2d;
  .text {
    text-align: left;
    padding: 0 5%;
  }
  .box {
    width: 100%;
    height: 250px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    .heading {
      font-size: 50px;
      color: black;
      text-align: center;
      font-weight: lighter;
    }
  }
}

@media (min-width: $mobile) {
  .about {
    .box {
      .heading {
        font-size: 75px;
      }
    }
  }
}

@media (min-width: $desktop) {
  .about {
    font-size: 30px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    .text {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: 5%;
    }
    .box {
      width: 500px;
      height: 500px;
      .heading {
        font-size: 100px;
      }
    }
  }
}
