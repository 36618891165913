@import '../../assets/style/default-imports.scss';

.link {
  cursor: pointer;
  color: #ffffff;
  border: none;
  background-color: transparent;
  max-width: min-content;
  font-size: 14px;

  @media (min-width: $tablet) {
      font-size: 18px;
  }

  @media (min-width: $desktop) {
    &:hover {
      animation: boingIn 0.5s;
      scale: 1.1;
    }
  }
}
