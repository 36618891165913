@import '../../assets/style/default-imports.scss';

.experience {
  min-height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000000;
  padding: 2.5% 10%;
  .header {
    width: 100%;
    display: flex;
    justify-content: center;
    h1 {
      color: #ffffff;
      text-transform: uppercase;
      font-weight: 100;
      font-size: 60px;
    }
  }

  .downloadButton {
    background-color: #ffffff;
    width: 100%;
    padding: 1% 2.5%;
    border-radius: 60px;
    border: none;
    margin-top: 10%;
    a {
      text-decoration: none;
      color: #000000;
      .download {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
      }
      .icon {
        height: 50px;
        justify-self: flex-start;
        width: 50px;
      }
    }
  }
}
@media (min-width: $desktop) {
  .experience {
    align-items: flex-end;
    .header {
      justify-content: center;
    }
    .downloadButton {
      width: max-content;
      a {
        .download {
          justify-content: center;
        }
      }
    }
  }
}
