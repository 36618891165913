@import '../../assets/style/default-imports.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #2e2d2d;
  .header {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .form {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
    .formWrapper {
      width: 75%;
    }
    .text {
      h1 {
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 100;
        font-size: 60px;
      }
    }
  }

  .paragraphContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    .paragraph {
      margin: 0;
      text-align: center;
      font-size: 30px;
      font-weight: lighter;
    }
  }

  .footer {
    width: 100%;
    .iconWrapper {
      display: flex;
      justify-content: center;
      a {
        color: white;
      }
      .icon {
        width: 75px;
        height: 75px;
      }
    }
  }
}

@media (min-width: $tablet) {
  .container {
    .footer {
      .iconWrapper {
      }
    }
  }
}

@media (min-width: $desktop) {
  .container {
    align-items: center;
    .form {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      text-align: right;
      width: 75%;
      gap: 2.5%;
      .text {
        flex: 1;
        h1 {
          margin: 2.5% 0;
          font-size: 80px;
          font-weight: 100;
        }
      }
      .formWrapper {
        flex: 1;
      }
    }
    .footer {
      .iconWrapper {
        justify-content: flex-start;
      }
    }
  }
}
