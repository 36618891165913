.hoverBounce {
  animation: boingOut 0.75s;
  &:hover {
    animation: boingIn 0.5s;
    scale: 1.1;
  }
}

.bounceLoop {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@keyframes boingIn {
  0% {
    scale: 1;
  }
  20% {
    scale: 1.2;
  }
  60% {
    scale: 1;
  }
  100% {
    scale: 1.1;
  }
}

@keyframes boingOut {
  0% {
    scale: 1.1;
  }
  20% {
    scale: 0.8;
  }
  60% {
    scale: 1.1;
  }
  100% {
    scale: 1;
  }
}
