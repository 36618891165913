@import '../../assets/style/default-imports.scss';

.contactForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5% 0;
  .buttonWrapper {
    width: 100%;
    .sendButton {
      background-color: #ffffff;
      width: 100%;
      padding: 1% 2.5%;
      border-radius: 60px;
      border: none;
      margin-top: 5%;
      cursor: pointer;
      font-size: 20px;
      font-weight: lighter;
    }
    .disabledButton {
      @extend .sendButton;
      color: #393939;
      background-color: #a8a8a8;
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}

@media (min-width: $mobile) {
  .contactForm {
    .buttonWrapper {
      .sendButton {
        font-size: 26px;
      }
    }
  }
}

@media (min-width: $desktop) {
  .contactForm {
    padding: 7.5% 0;
    .buttonWrapper {
      .sendButton {
        width: 25%;
      }
    }
  }
}
