.inputWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  textarea:focus,
  input:focus {
    outline: none;
  }
  .title {
    font-size: 16px;
    margin: 0;
    text-align: left;
  }
  .input {
    padding: 10px;
    font-size: 14px;
    border-radius: 2px;
    border-style: solid;
    box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.025);
    border: 1px solid #eaeaea;
    align-self: stretch;
  }
  textarea {
    resize: none;
    height: 100px;
  }
  .error {
    border-color: rgb(118, 6, 6);
    background-color: rgba(255, 0, 0, 0.75);
  }
  .hiddenError {
    visibility: hidden;
    height: 16.5px;
    margin-top: 0.25rem;
  }

  .errorMessage {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
}
