@import '../../assets/style/default-imports.scss';

.topbar {
  position: sticky;
  top: 0;
  min-width: 100vw;
  background-color: rgba($color: $black, $alpha: 0.5);
  backdrop-filter: blur(10px);
  z-index: 2;
  .content {
    display: flex;
    align-items: center;
    height: 70px;
    gap: 5%;
    padding: 0 2.5%;
    .icons {
      display: flex;
      a {
        text-decoration: none;
        color: #ffffff;
      }
      .icon {
        width: 50px;
        height: 50px;
      }
    }
    .links {
      display: flex;
      justify-content: space-evenly;
      min-width: 50%;
      font-weight: 600;
      width: 100%;
      font-size: 14px;
    }
  }
}

.progressBar {
  top: 70px;
  height: 2px;
  background: $lavender;
  transform-origin: 0%;
}

@media (min-width: $mobile) {
  .topbar {
    .content {
      .icons {
        .icon {
          width: 75px;
          height: 75px;
        }
      }
    }
  }
}

@media (min-width: $lg-mobile) {
  .topbar {
    .content {
      padding: 0 10%;
    }
  }
}
@media (min-width: $tablet) {
  .topbar {
    .content {
      .icons {
        display: flex;
      }
    }
  }
}

@media (min-width: 1024px) {
  .topbar {
    .content {
      justify-content: space-between;
      gap: unset;
      padding: 0 5%;
      .links {
        width: unset;
      }
    }
  }
}
