@import '../../assets/style/default-imports.scss';

.timeline {
  width: 100%;
  .container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    .vr {
      height: 100%;
      background-color: #ffffff;
      width: 1px;
      top: 0;
      left: 2.5%;
      position: absolute;
    }
    .timelineBlock {
      display: flex;
      margin-top: 5%;
      margin-bottom: 10%;
      .card {
        position: relative;
        background-color: #2e2d2d;
        width: 70vw;
        padding: 2%;
        color: #ffffff;
        border-radius: 5px;
        .role {
          font-size: 24px;
        }
        .employer {
          font-size: 20px;
        }
        .experience {
          font-size: 16px;
        }
        .mobileDate {
          text-transform: capitalize;
        }
      }
      .left {
        left: 15%;
      }
      .right {
        left: 15%;
      }
      .leftDate {
        display: none;
      }
      .rightDate {
        display: none;
      }
      .iconWrapper {
        background: #000000;
        border-radius: 100%;
        width: min-content;
        position: absolute;
        padding: 5px;
        left: calc(2.5% - 22.5px);
        transform: translate(-50%, 0);
        z-index: 1;
        .icon {
          height: 35px;
          color: #ffffff;
          width: 35px;
        }
      }
    }
  }
}

@media (min-width: $lg-mobile) {
  .timeline {
    .container {
      .timelineBlock {
        .left {
          left: 10%;
        }
        .right {
          left: 10%;
        }
      }
    }
  }
}

@media (min-width: $tablet) {
  .timeline {
    .container {
      .timelineBlock {
        justify-content: center;
        .card {
          width: 55vw;
        }
        .iconWrapper {
          background-color: #000000;
        }
      }
    }
  }
}

@media (min-width: $desktop) {
  .timeline {
    .container {
      .timelineBlock {
        .card {
          width: 60vw;
        }
      }
    }
  }
}

@media (min-width: $lg-desktop) {
  .timeline {
    .container {
      height: unset;
      .vr {
        left: 50%;
      }
      .timelineBlock {
        display: flex;
        align-items: center;
        height: 250px;
        margin-top: unset;
        margin-bottom: unset;
        .card {
          position: absolute;
          width: 500px;
          .experience {
            font-size: 14px;
            line-height: 15px;
            font-weight: 400;
          }
          .mobileDate {
            display: none;
          }
        }
        .left {
          left: 0px;
          transform: unset;
        }
        .right {
          right: 0px;
          left: unset;
          transform: unset;
        }
        .leftDate {
          position: absolute;
          left: 45vw;
          display: unset;
        }
        .rightDate {
          position: absolute;
          right: 45vw;
          display: unset;
        }
        .iconWrapper {
          left: calc(50% - 22.5px);
          .icon {
            height: 35px;
            width: 35px;
          }
        }
      }
    }
  }
}
