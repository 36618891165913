.toast {
    position: relative;
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 0px;
    left: 0px;
  
    p {
      margin: 0;
    }
  
    button {
      background: none;
      border: none;
      color: white;
      cursor: pointer;
    }
  
    &.success {
      background-color: #4caf50;
    }
  
    &.error {
      background-color: #f44336;
    }
  
    &.info {
      background-color: #2196f3;
    }
  
    &.warn {
      background-color: #ff9800;
    }
  }
  