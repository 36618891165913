.toastContainer {
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 300px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  